body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  outline: none;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div[data-testid="underlay"] {
  pointer-events: none;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('./fonts/NeueHaasGroteskText-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal !important;
}

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('./fonts/NeueHaasGroteskText-BoldIta.otf') format('opentype');
  font-weight: bold;
  font-style: italic !important;
}

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('./fonts/NeueHaasGroteskText-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic !important;
}

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('./fonts/NeueHaasGroteskText-MedIta.otf') format('opentype');
  font-weight: medium;
  font-style: italic !important;
}

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('./fonts/NeueHaasGroteskText-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal !important;
}

@font-face {
  font-family: 'Neue Haas Grotesk Text';
  src: url('./fonts/NeueHaasGroteskText-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal !important;
}

.display-large {
  font-family: 'Neue Haas Grotesk Text', sans-serif !important;
  font-size: 57px !important;
  font-weight: 400 !important;
}

.display-medium {
  font-family: 'Neue Haas Grotesk Text', sans-serif !important;
  font-size: 45px !important;
  font-weight: 400 !important;
}

.display-small {
  font-family: 'Neue Haas Grotesk Text', sans-serif !important;
  font-size: 36px !important;
  font-weight: 400 !important;
}

.headline-large {
  font-family: 'Neue Haas Grotesk Text', sans-serif !important;
  font-size: 32px !important;
  font-weight: 400 !important;
}

.headline-medium {
  font-family: 'Neue Haas Grotesk Text', sans-serif !important;
  font-size: 28px !important;
  font-weight: 400 !important;
}

.headline-small {
  font-family: 'Neue Haas Grotesk Text', sans-serif !important;
  font-size: 24px !important;
  font-weight: 400 !important;
}

.title-large-format {
  font-family: 'Neue Haas Grotesk Text', sans-serif !important;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}

.title-price {
  font-family: 'Neue Haas Grotesk Text', sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.title-large {
  font-family: 'Neue Haas Grotesk Text', sans-serif !important;
  font-size: 22px !important;
  font-weight: 400 !important;
}

.title-medium {
  font-family: 'Neue Haas Grotesk Text', sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.title-small-date {
  font-family: 'Neue Haas Grotesk Text', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.title-small {
  font-family: 'Neue Haas Grotesk Text', sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.label-large {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.label-medium {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.label-small {
  font-family: 'Roboto', sans-serif !important;
  font-size: 11px !important;
  font-weight: 400 !important;
}

.body-large {
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.body-medium {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.body-small {
  font-family: 'Roboto', sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.bold {
  font-weight: 500 !important;
}

.thin {
  font-weight: 400 !important;
}

.editor-font {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #9b9b9b;
  border-radius: 5px;
  border: 5px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #333333;
}

::-webkit-scrollbar-thumb:active {
  background-color: #1a1a1a;
}